export const productQuestions = [
	{
		title: 'Care este diferența între culorile RGB și CMYK?',
		content:
			'RGB (red, green, blue) este optim pentru afișajele digitale unde lumina este emisă direct. Imprimarea necesită absorbția și reflexia luminii de către cerneală pe hârtie, ceea ce face ca CMYK (cyan, magenta, yellow, key (black)) să fie alegerea practică pentru acest mediu.',
	},
	{
		title: 'De ce culorile alese arată ușor diferite printate?',
		content:
			'Deoarece înainte de printare, facem conversia de culori de la RGB la CMYK pentru a obține cea mai bună calitate. Astfel, vă îndrumăm să nu alegeți culori RGB foarte aprinse, ce nu se regăsesc în CMYK.',
	},
	{
		title: 'Care sunt pașii de personalizare al tabloului?',
		content:
			'Alege ce fel de tablou ai dori și accesează pagina de personalizare aferentă. ' +
			'în funcție de tabloul ales, consultă semnificația fiecărei culori și fă-ți propriul design. \n' +
			'Alege și culoarea font-ului dintre culorile deja alese pentru tablou. Selectează o ramă potrivită pentru tabloul tău :)',
	},
];

export const orderQuestions = [
	{
		title: 'Cine îmi livreză produsele?',
		content:
			'Partenerii noștri de încredere pentru livrări sunt Sameday, care asigură transport pe tot teritoriul României.',
	},
	{
		title: 'Cât durează să ajungă comanda pe care am plasat-o?',
		content:
			'Pregătirea și ambalarea comenzii pot dura până la 2 zile lucrătoare, iar serviciul de curierat face tot posibilul să livreze comandă în 1-2 zile lucrătoare de la momentul plasării.',
	},
	{
		title: 'Pot avea și alte cerințe de personalizare al tabloului?',
		content:
			'În cazul în care dorești să faci o modificare în plus tabloului (de exemplu, eliminarea textului),  trimite un mesaj la hello@selfposters.ro sau scrie-ne pe instagram la @selfposters.ro',
	},
	{
		title: 'Ce fac dacă comanda mea nu a sosit?',
		content:
			'Facem tot posibilul să nu existe astfel de experienţe. Dacă totuşi au trecut mai mult de 5 zile lucrătoare şi comanda nu s-a livrat, scrie-ne la hello@selfposters.ro sau pe instagram la @selfposters.ro şi ne vom asigura că produsele vor ajunge în cel mai scurt timp.',
	},
	{
		title: 'Am primit un produse defect, ce să fac?',
		content:
			'Ne pare rău pentru această experienţă, ştim că pot exista greşeli în procesare, dar împreună cu suportul comunităţii noastre reuşim întotdeauna să remediem situaţiile neplăcute. Contactează-ne pe email la hello@selfposters.ro sau pe instagram la @selfposters.ro şi vom rezolva în cel mai scurt timp.',
	},
	{
		title: 'Pot returna tabloul personalizat?',
		content:
			'Din păcate, produsele personalizate care respectă cerințele clientului și primite fără defecte, nu pot fi returnate.',
	},
	{
		title: 'Ramele se pot returna?',
		content:
			'Returnarea ramelor este permisă, până la 14 zile de la primirea comenzii. Dacă se dorește returnarea unui produs, vă rugăm să inițiați acțiunea trimițându-ne un email pe hello@selfposters.ro. ',
	},
	{
		title: 'În cât timp se vor restitui banii în cazul unui retur?',
		content:
			'Restituirea va fi primită în termen de 5-10 zile de la primirea produsului returnat.',
	},
];
