const benefits = [
	{
		title: 'ELEVATE YOUR WALLS',
		desc: 'Transform your space with our premium color-based canvas posters, designed to add depth, character, and a touch of personality to any room.',
	},
	{
		title: 'SENSE THE QUALITY',
		desc: 'We use 100% cotton canvas, a material that guarantees quality and durability of colors.',
	},
	{
		title: 'EXPRESS YOURSELF',
		desc: 'Let your walls reflect your personality. Each piece helps you create a space that feels truly yours—bold, artistic, and full of character.',
	},
];

export default benefits;
