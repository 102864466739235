export const price = 60.0;

export const HOME_DELIVERY_SMALL = 19;
export const LOCKER_DELIVERY_SMALL = 14;
export const HOME_DELIVERY_BIG = 25;
export const LOCKER_DELIVERY_BIG = 14;
export const freeFraming =
	'When you purchase a canvas art print and frame in matching sizes, we will frame it for free.';
export const deliveryDetails =
	'Delivery is made by Sameday and it takes up to 3-5 days. You can choose between home or easybox locker delivery.';
export const details =
	'Print: 100% cotton canvas with a matte finish\n\nFrames\nFrame Material: 100% FSC® certified natural wood\nMounting Method: Equipped with a hanger\nPicture Protection: Plexiglass\n\nStretcher (Chassis):\nMaterial: Dried softwood';
export const stickerDetails = 'Size: 15x20cm\nSticker Sheet';

export const postersDetails =
	'Print: 100% cotton canvas with a matte finish\n\nFrames\nFrame Material: 100% FSC® certified natural wood\nMounting Method: Equipped with a hanger\nPicture Protection: Plexiglass\n\nStretcher (Chassis):\nMaterial: Dried softwood';

export const framesDetails =
	'Print: 100% cotton canvas with a matte finish\n\nFrames\nFrame Material: 100% FSC® certified natural wood\nMounting Method: Equipped with a hanger\nPicture Protection: Plexiglass\n\nStretcher (Chassis):\nMaterial: Dried softwood';

export const suport =
	"For questions, don't hesitate to contact us at hello@selfposters.ro, or on Instagram/TikTok @selfposters.ro ♡";
export const atentionareCuloare =
	'Vă sfătuim să nu alegeți culori stridente. Tehnologia actuală nu permite printarea culorilor în format RGB, ci CMYK. Astfel, culorile stridente pot ieși la imprimare ușor mai închise.';

export const daia_description =
	'Daia currently living and working in Bucharest as a visual artist and as a graphic designer. Her artworks are characterized by vibrant, bold colors — with a soft spot for the depth of purple — all merging together with organic lines and shapes. She draws inspiration mostly from nature and from little day-to-day thoughts and happenings.';
export const shippingMessage = 'Transport gratuit la comenzi peste 240 RON!';

export const headerMessage = 'La 3 tablouri cumpărate, al 3 lea este gratuit';

export const color0 = {
	r: '255',
	g: '190',
	b: '11',
};

export const color2 = {
	r: '251',
	g: '86',
	b: '7',
};

export const color3 = {
	r: '255',
	g: '0',
	b: '110',
};

export const color1 = {
	r: '255',
	g: '163',
	b: '163',
};

export const fontColors = [
	'Prima culoare',
	'A doua culoare',
	'A treia culoare',
	'A patra culoare',
];
export const subheadersLabelData = ['Featured'];
export const subheadersData = new Map([
	[
		'Featured',
		[
			{
				label: 'Daia Grigore Collection',
				href: '/journal/daia-grigore',
			},
		],
	],
]);

export const headersData = [
	{
		label: 'Home',
		href: '/',
	},
	{
		label: 'Canvas Art Prints',
		href: '/canvas-art-prints',
	},
	{
		label: 'Customized Canvas Posters',
		href: '/customized-canvas-posters',
	},
	{
		label: 'Journal',
		href: '/journal',
	},
];

export const prices = new Map([
	['21x30cm', 60],
	['30x40cm', 90],
]);

export const sizes = ['21x30cm', '30x40cm'];
export const chassisPrices = new Map([
	['21x30cm', 30],
	['30x40cm', 50],
]);

export const framePrices = new Map([
	['21x30cm', 35],
	['30x40cm', 55],
]);

export const posterPrices = new Map([
	[
		'21x30cm',
		new Map([
			[60, 60],
			[120, 120],
		]),
	],
	[
		'30x40cm',
		new Map([
			[60, 90],
			[120, 150],
		]),
	],
]);

export const contactData = [
	{
		label: 'Self Posters Club',
		href: '/our-club',
	},
	{
		label: 'About Us',
		href: '/about-us',
	},
	{
		label: 'FAQ',
		href: '/faq',
	},
	{
		label: 'Contact',
		href: '/contact',
	},
];
