import { S3_BUCKET } from './links';

const inspo1 = S3_BUCKET + '/inspo1.webp';
const inspo2 = S3_BUCKET + '/inspo2.webp';
const inspo3 = S3_BUCKET + '/inspo3.webp';

const inspo4 = S3_BUCKET + '/inspo4.webp';
const inspo6 = S3_BUCKET + '/inspo6.webp';
const inspo7 = S3_BUCKET + '/inspo7.webp';
const inspo8 = S3_BUCKET + '/inspo8.webp';
const inspo9 = S3_BUCKET + '/inspo9.webp';
const inspo10 = S3_BUCKET + '/inspo10.webp';
const inspo11 = S3_BUCKET + '/inspo11.webp';
const inspo14 = S3_BUCKET + '/inspo14.webp';
const inspo15 = S3_BUCKET + '/inspo15.webp';
const inspo17 = S3_BUCKET + '/diningroom.webp';
const inspo16 = S3_BUCKET + '/livingarea.webp';
const inspo18 = S3_BUCKET + '/diningarea.webp';
const inspo19 = S3_BUCKET + '/inspo19.webp';
const inspo20 = S3_BUCKET + '/inspo21.webp';
const inspo21 = S3_BUCKET + '/leleCafe.webp';
const inspo22 = S3_BUCKET + '/WaterShowcase1.webp';
const inspo23 = S3_BUCKET + '/WaterShowcase2.webp';
export const inspoData = [
	{
		image: inspo21,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo20,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo23,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo22,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},

	{
		image: inspo19,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},

	{
		image: inspo14,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},

	{
		image: inspo15,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo18,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo4,
		heading: 'Slide One',
		desc: 'This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo8,
		heading: 'Slide Five',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo9,
		heading: 'Slide Seven',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo6,
		heading: 'Slide Seven',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo7,
		heading: 'Slide Eight',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo10,
		heading: 'Slide Eight',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo11,
		heading: 'Slide Eight',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo1,
		heading: 'Slide Two',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: inspo2,
		heading: 'Slide Three',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},

	{
		image: inspo3,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
];
