export const articles = [
	{
		imgTitle: 'aboutDaia.webp',
		title: 'Daia Grigore Collection | Salt Water',
		date: '7th March 2025',
		urlTitle: 'daia-grigore',
		description: 'Read more about Daia and her artworks.',
	},
	{
		imgTitle: 'giftguide.webp',
		title: 'Holiday Gift Guide',
		date: '26th November 2024',
		urlTitle: 'gift-guide',
		description:
			'Get inspired with a selection of our favourite posters to gift your loved ones – including yourself – during the holiday season.',
	},
	{
		imgTitle: 'image00003.webp',
		title: 'Color Psychology',
		date: '6th November 2024',
		urlTitle: 'color-psychology',
		description:
			'Understanding color psychology allows us to harness its effects to create meaningful connections, enhance aesthetics, and evoke desired emotions in any setting. Read more',
	},
	{
		imgTitle: 'inspo15.webp',
		title: 'Inspiration',
		date: '25th October 2024',
		urlTitle: 'inspiration',
		description:
			"Tired of the same old room decor? Well, guess what? You don't have to settle for it! Spruce up your space with some fabulous contemporary prints and posters that will take your bedroom to the next level",
	},
];
