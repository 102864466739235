import { S3_BUCKET } from './links';

const inspo16 = S3_BUCKET + '/diningarea.webp';
const inspo17 = S3_BUCKET + '/livingarea.webp';
const inspo4 = S3_BUCKET + '/inspo4.webp';
const inspo8 = S3_BUCKET + '/inspo8.webp';
const inspo14 = S3_BUCKET + '/inspo14.webp';
const inspo15 = S3_BUCKET + '/inspo15.webp';
const inspo19 = S3_BUCKET + '/inspo19.webp';
const inspo20 = S3_BUCKET + '/inspo21.webp';
const inspo21 = S3_BUCKET + '/leleCafe.webp';
const inspo22 = S3_BUCKET + '/WaterShowcase1.webp';
const inspo23 = S3_BUCKET + '/WaterShowcase2.webp';

export const inspoHome = [
	{
		image: `url(${inspo21})`,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: `url(${inspo20})`,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},

	{
		image: `url(${inspo22})`,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: `url(${inspo23})`,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: `url(${inspo16})`,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},

	{
		image: `url(${inspo15})`,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: `url(${inspo17})`,
		heading: 'Slide Four',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: `url(${inspo14})`,
		heading: 'Slide One',
		desc: 'This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},

	{
		image: `url(${inspo15})`,
		heading: 'Slide One',
		desc: 'This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},

	{
		image: `url(${inspo4})`,
		heading: 'Slide One',
		desc: 'This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
	{
		image: `url(${inspo8})`,
		heading: 'Slide Five',
		desc: 'This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.',
	},
];
