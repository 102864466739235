const colors = [
	{
		title: 'Red',
		color: '#ef233c',
		summary: 'passion, vitality, creative energy',
		desc: 'Red is often associated with passion and vitality, but also with power and determination. From a spiritual perspective, red can symbolize inner fire, courage, and creative energy. It can also represent divine love and compassion.',
	},
	{
		title: 'Blue',
		color: '#3a86ff',
		summary: 'depth, trust, devotion',
		desc: 'Blue is the color of the sky and sea, suggesting infinity and depth. In a spiritual context, blue can represent trust, peace, and mental calmness. It is often associated with wisdom and spiritual devotion.',
	},
	{
		title: 'Green',
		color: '#0a9396',
		summary: 'healing, balance, regeneration',
		desc: 'Green is the color of nature and life. It is often considered the color of healing and balance. In spirituality, green can symbolize spiritual blossoming, regeneration, and harmony. It may also suggest trust in the process of growth and transformation.',
	},
	{
		title: 'Yellow',
		color: '#fff185',
		summary: 'clarity, understanding, optimism',
		desc: 'Yellow is the color of light and solar energy. In a spiritual context, yellow can symbolize enlightenment and inner understanding. It represents optimism, joy, and mental clarity. It may also represent the pursuit of wisdom and divine knowledge.',
	},
	{
		title: 'Orange',
		color: '#f48c06',
		summary: 'creativity, free self-expression, inner exploration',
		desc: "Orange combines the energy of red with the joy of yellow. In spirituality, orange can symbolize enthusiasm, creativity, and free self-expression. It may also represent the search for life's meaning and inner exploration.",
	},
	{
		title: 'Purple',
		color: '#3a0ca3',
		summary: 'spiritual evolution, intuition, integrity',
		desc: 'Purple is a mysterious and profound color. In a spiritual context, purple can represent transformation and spiritual evolution. It is often associated with intuition and connection with higher planes of consciousness. Purple may also symbolize healing and inner balance.',
	},
	{
		title: 'Pink',
		color: '#ffc8dd',
		summary: 'compassion, gentleness, acceptance',
		desc: 'Pink is the color of love and compassion. In a spiritual context, pink can symbolize divine love, kindness, and care for others. It is often associated with forgiveness and unconditional acceptance.',
	},
	{
		title: 'Brown',
		color: '#9e2a2b',
		summary: 'stability, security, authenticity',
		desc: "Brown is the color of earth and stability. In spirituality, brown can symbolize grounding and inner security. It represents trust in life's process and connection with the fundamental energies of the universe.",
	},
	{
		title: 'Black',
		color: '#000000',
		summary: 'transformation, rebirth, self-knowledge',
		desc: 'Black is the color of mystery and the unknown. In a spiritual context, black can symbolize transformation and rebirth. It is often associated with regeneration and deep processes of introspection and self-knowledge.',
	},
	{
		title: 'White',
		color: '#f4f1de',
		summary: 'purity, spiritual enlightenment, wisdom',
		desc: 'White is the color of purity and divine light. In spirituality, white can represent soul purity and spiritual enlightenment. It is associated with divine wisdom, mental clarity, and the state of enlightenment.',
	},
];

export default colors;
